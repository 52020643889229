import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamLeaderboar } from '../../store/TeamLeaderboar/teamLeaderboar.thunk';
import { ListLeaderboard, ProgressLoader } from '../../components';

import { IState } from '../../types';

const ProgressTeam: React.FC = () => {
  const dispatch = useDispatch();
  const progressMy = useSelector((state: IState) => state.progressMy.data);
  const teamLeaderboard = useSelector((state: IState) => state.teamLeaderboard.data);

  useEffect(() => {
    if (progressMy && progressMy.teamId) {
      dispatch(getTeamLeaderboar(progressMy.teamId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressMy]);

  if (!progressMy || !teamLeaderboard) return null;
  
  const {
    team: { goals, progress, targetSize },
  } = progressMy;

  return (
    <>
      <ProgressLoader
        valueCoin={progress.raisedSum}
        valueTime={progress.minsSum}
        totalValueTime={goals.readingMinsPerMember * targetSize}
        totalValueCoin={goals.fundraisingPerMember * targetSize}
      />
      <ListLeaderboard
        style={{ paddingBottom: '110px' }}
        name={'Team Leaderboard'} list={teamLeaderboard} />
    </>
  );
};

export default ProgressTeam;
