import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { getListTeam } from '../../../store/listTeam/listTeam.thunk';
import { getCurrentTeam } from '../../../store/SignUpData/signUpData.thunk';
import API from '../../../REST_API/listApi';
import { Check } from '../../../assets/images/icons';
import { IEvent, IState, ITeam } from '../../../types';
import styles from '../SignUp.module.scss';
import { useHistory } from 'react-router-dom';
import stylesChooseTeam from './ChooseTeam.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import ButtonNavigation from '../ButtonNavigation/ButtonNavigation';
import { useParams } from 'react-router-dom';
import { analytics } from "../../../analytics";
import { ACCESS_TOKEN, SIGN_UP_TEAM_ID } from "../../../const";
import { Select } from "../../../components";

interface IChooseTeam {
  event: IEvent;
  page: {
    activePage: number;
    setActivePage: (page: number) => void;
    code: string;
    setCode: (code: string) => void;
  };
  list: string[];
}

const ChooseTeam: React.FC<IChooseTeam> = ({event, page, list}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: IState) => state.user.data);
  const invite = useSelector((state: IState) => state.signUpData.data);
  const selectedTeam = useSelector((state: IState) => state.signUpData.currentTeam);
  const [currentTeam, setCurrentTeam] = useState<null | number>(-1);
  const params = useParams<{ code: string }>();
  const listTeam: ITeam[] | null = useSelector((state: IState) => state.listTeam.data);
  const [isDisabled, setIsDisabled] = useState(true);
  const myRef = useRef(null);
  useEffect(() => {
    page.setActivePage(1);
  }, [page]);

  useEffect((): void => {
    analytics.logView('sign_up_teams');
    if (event) dispatch(getListTeam(event.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const handleScroll = () => {
    // @ts-ignore
    myRef.current.scrollIntoView(false);
  };

  useEffect(() => {
    setIsDisabled(true);
    handleScroll();
    page.setCode(params.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeColor = (index: number) => {
    const id = index / 3;
    if (index < 3) {
      return index;
    }
    if (Math.trunc(id) === id) {
      return 0;
    }
    return Math.round(index / 3);
  };


  const onNextClickHandler = user ? () => {
    API.teams.setTeam(selectedTeam.id, selectedTeam.inviteCode || '');
    history.push(`/sign-up/profile`)
  } : undefined;

  const handleChangeTeam = (index: number) => {
    if(listTeam) {
      setCurrentTeam(index)
      setIsDisabled(false);
      let team: ITeam = listTeam[index]
      localStorage.setItem(SIGN_UP_TEAM_ID, `${team?.id}`)
      let newVar = {...team};
      if (invite.inviteRole == 'TL') {
        newVar.inviteCode = params.code;
      }
      dispatch(getCurrentTeam(newVar));
    }
  }

  return (
    <>
      <div className={stylesChooseTeam.wrapper}>
        <div className={stylesChooseTeam.listcard}>
          <span ref={myRef} className={styles.text}>
            Choose your team
          </span>

          {listTeam ?
            <Select

              value={currentTeam || ''} onChange={(index) => {handleChangeTeam(index)}}>
              {listTeam?.map((team, index) => (
                <option value={index} key={team.id}>
                  {team.name}
                </option>))}
            </Select> : (
              <div className={styles.filler}></div>
            )}

          {user && <div className={stylesChooseTeam.loggedAs}>
              You are logged as <strong>{user.displayName}</strong><br/>
              You will join the new team after clicking the Next button.
          </div>}

        </div>
      </div>
      <ButtonNavigation
        onClick={onNextClickHandler}
        isDisabled={isDisabled} list={list} page={{...page, activePage: 1}}/>
    </>
  );
};

export default ChooseTeam;
