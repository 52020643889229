import React from 'react';

import styles from './Beneficiary.module.scss';
import { IUser } from "../../../types";
import { IPublicEvent } from "../../../store/DonationPage/types";

interface Props {
  event: IPublicEvent;
}

const BeneficiaryEA = ({event}: Props) => {
  let caption = ' ';

  return (
    <div className={`${styles.Beneficiary}`}>
      {event.details.photo ?
        <img className={styles.cover} src={event.details.photo} alt=""/> :
        <div className={styles.avatarBg}>
          <img src={event.invite.from.avatar} alt=""/>
        </div>
      }
    </div>
  );
};


export default BeneficiaryEA;
