import { ISchoolLeaderboard } from '../../types';
import { createSlice } from '@reduxjs/toolkit';
import { getEventMembers } from './schoolLeaderboard.thunk';

interface IinitialState {
  error: boolean | string;
  data: null | ISchoolLeaderboard;
  loading: boolean;
}

const reducerSchoolLeaderboard = createSlice({
  name: 'schoolLeaderboard',
  initialState: { error: false, data: null, loading: false } as IinitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventMembers.pending, (state: IinitialState) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getEventMembers.fulfilled, (state: IinitialState, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getEventMembers.rejected, (state: IinitialState) => {
      state.loading = false;
    });
  },
});

export default reducerSchoolLeaderboard.reducer;
