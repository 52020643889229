import React, { useEffect, useState } from 'react';


import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { IState } from "../../../types";
import { getPublicEvent } from "../../../store/DonationPage/thunks";
import { Checkbox, ErrorModal, Input, LoaderData, Textarea } from "../../../components";


import styles from './DonateTo.module.scss';
import { ArrowIcon } from "../../../assets/images/icons";
import AmountSelector from "./AmountSelector/AmountSelector";
import StripeCheckoutButton from "./StripeCheckoutButton";
import eventsApi, { OrderRequest, OrderSummary } from "../../../REST_API/eventsApi";
import Beneficiary from "../Beneficiary/Beneficiary";
import { analytics } from "../../../analytics";
import Tips from "./Tips/Tips";
import BookOptIn from "./BookOpt";
import { formatAmount } from "../../../components/UI/GetCurrency/GetCurrency";
import DonorFee from "./DonorFee";
import Details from "./Details";

interface Props extends RouteComponentProps {

}

const DonateTo = ({history}: Props) => {
  const {code} = useParams<{ code: string }>();
  const [amount, setAmount] = useState(20);
  const [order, setOrder] = useState<OrderSummary>();
  const [tipsAmount, setTipsAmount] = useState(0);
  const [bookBoxAmount, setBookBoxAmount] = useState('');
  const [name, setName] = useState('');
  const [donorFee, setDonorFee] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPublicCheckboxChecked, setIsPublicCheckboxChecked] = useState(true);
  const [isCorporateCheckboxChecked, setIsCorporateCheckboxChecked] = useState(false);
  const [isGiftAidCheckboxChecked, setIsGiftAidCheckboxChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const event = useSelector((state: IState) => state.dp.event);
  const user = useSelector((state: IState) => state.user.data);
  const giftAidEnabled = event && event.location.country === 'UK';

  const isDisabled = name === '' || email === '' ||
    !event || event.closed || !event.stripeAccount || donorFee == '' ||
    (bookBoxAmount == '' && event.options.bookOptIn)

  const charge = (token: string) => {
    setIsLoading(true);
    if (event) {
      eventsApi.payment({
        eventId: event.id,
        name, message, stripeEmail: email.trim().toLowerCase(),
        public: isPublicCheckboxChecked,
        matchCorp: isCorporateCheckboxChecked,
        giftAid: isGiftAidCheckboxChecked,
        donorFee: donorFee,
        bookBoxAmount: Number(bookBoxAmount),
        donationAmount: amount,
        tipsAmount: tipsAmount,
        stripeToken: token,
        targetUserId: event.invite.from.id
      }).then((res) => {
        setIsLoading(false);
        if (res.ok) {
          history.push(history.location.pathname + '-success-' + amount + '-' + (bookBoxAmount || 0) );
        } else {
          res.json().then(errorResponse => {
            console.error('charge error', errorResponse.error);
            setErrorMessage(errorResponse.error.message);
          });
        }
      }).catch(e => {
        console.error('charge error', e);
        setIsLoading(false);
        setErrorMessage("Payment error, please try again.");
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPublicEvent(code));
    if (user) setEmail(user.email);
    analytics.logView('donate_to');
  }, []);

  useEffect(() => {
    if (event)
      eventsApi.orderSummary({
        eventId: event?.id,
        donationAmount: amount,
        bookBoxAmount: bookBoxAmount || 0,
        tipsAmount: tipsAmount,
        donorFee: donorFee == 'true'
      } as OrderRequest).then(order => setOrder(order.data))
  }, [event, amount, tipsAmount, donorFee, bookBoxAmount]);

  return (
    <div className={styles.DonateTo}>
      <div className={styles.contentContainer}>

        {event ?
          <div className={styles.content}>

            <header>
              <div onClick={history.goBack} className={styles.backButton}>
                <ArrowIcon/>
              </div>
              <h1 className={styles.title}>Donate to</h1>
            </header>


            {event && <>

                <Beneficiary user={event.invite.from}/>

                <AmountSelector amount={amount}
                                currency={event.currency}
                                onChange={setAmount}/>

            </>}

            <div className={styles.form}>
              <Input placeholder="From who" value={name}
                     disabled={isLoading}
                     onChange={(e: any) => setName(e.target.value)}/>
              <Input placeholder="Your email"
                     disabled={isLoading}
                     onChange={(e: any) => setEmail(e.target.value)}
                     value={email}/>
              <Textarea placeholder="Your message"
                        value={message}
                        onChange={(e: any) => setMessage(e.target.value)}
                        disabled={isLoading}/>

              <div className={styles.checkbox}
                   onClick={() => setIsPublicCheckboxChecked(!isPublicCheckboxChecked)}>
                <Checkbox isChecked={isPublicCheckboxChecked}/>
                <label>
                  Show my donation on the fundraiser donation page
                </label>
              </div>

              <div className={styles.checkbox}
                   onClick={() => setIsCorporateCheckboxChecked(!isCorporateCheckboxChecked)}>
                <Checkbox isChecked={isCorporateCheckboxChecked}/>
                <label>
                  Contact me about corporate matching this donation
                </label>
              </div>


              {giftAidEnabled && <>
                  <div className={styles.checkbox}
                       onClick={() => setIsGiftAidCheckboxChecked(!isGiftAidCheckboxChecked)}>
                      <Checkbox isChecked={isGiftAidCheckboxChecked}/>
                      <label>
                          I would like to claim Gift Aid for this donation.
                      </label>
                  </div>
                  <div className={styles.checkboxDetails}>
                      By ticking this checkbox, I agree I would like this organisation to reclaim the tax on
                      this donation
                      as well as any future donations to this organisation, until I notify them otherwise. I
                      understand that
                      if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on
                      all my
                      donations in that tax year I may be asked to pay any difference. I understand that this
                      organisation
                      will reclaim 25p of tax on every £1 that I give.
                  </div>
              </>}

              {event.options.bookOptIn ?
                <BookOptIn displayName={event.invite.from.firstName}
                           schoolName={event?.location.schoolName} amount={bookBoxAmount}
                           onChange={(amount) => {setBookBoxAmount(amount)}}/> : ''}

              {event.stripeAccount && event.stripeAccount.businessModel === 'tips' &&
                  <Tips
                      schoolName={event?.location.schoolName}
                      options={event.stripeAccount.tipsOptions}
                      amount={amount} currency={event?.currency}
                      onChangeTipsAmount={setTipsAmount}/>}


              <DonorFee accepted={donorFee}
                        schoolName={event?.location.schoolName}
                        currency={event.currency}
                        fee={(order?.feeAmount || 0) + (order?.processingFeeAmount || 0) - (Number(bookBoxAmount || 0))}
                        onChange={(accepted) => {setDonorFee(accepted)}}/>

              <Details donationAmount={amount}
                       bookBoxAmount={Number(bookBoxAmount)}
                       chargeAmount={order?.chargeAmount || 0}
                       feesAmount={donorFee === 'true' ? ((order?.feeAmount || 0) + (order?.processingFeeAmount || 0) - Number(bookBoxAmount)) : 0}
                       currency={event.currency}/>


              <StripeCheckoutButton
                amount={order?.chargeAmount}
                disabled={isDisabled}
                isLoading={isLoading}
                title={'Read for My School'}
                currency={event?.currency}
                onSuccess={charge}
                email={email.trim().toLowerCase()}>
                Donate now ({formatAmount(order?.chargeAmount || amount, event.currency)})
              </StripeCheckoutButton>

              {(!event.stripeAccount || event.closed) &&
                  <p className={styles.noStripe}>
                      Your fundraiser is currently not accepting pledge donations at this time.
                      Please contact your event administrator for details.
                  </p>}


            </div>

          </div> :
          <LoaderData className={styles.loader} colorWhite={true}/>
        }

        <ErrorModal cancel={() => {
          setErrorMessage('')
        }} isOpen={errorMessage !== ''} title={errorMessage}/>

      </div>
    </div>
  );
};

export default withRouter(DonateTo);
