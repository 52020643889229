import React from 'react';
import { useSelector } from 'react-redux';

import { IState } from '../../../types';

interface IGetCurrency {
  value: string | number;
}

export const currencyIcon: {[key: string]: string} = {
  USD: '$', EUR: '€', GBP: '£', AUD: '$', CAD: '$'
};

const GetCurrency: React.FC<IGetCurrency> = ({value}): JSX.Element => {
  const events = useSelector((state: IState) => state.events.data);

  if (!events) return <>{value}</>;
  return <>{currencyIcon[events.currency] + value.toLocaleString()}</>;
};

export const GetCurrencyString = (value: string | number) => {
  const events = useSelector((state: IState) => state.events.data);

  if (!events) return value;
  return currencyIcon[events.currency] + value.toLocaleString();
};


export const formatAmount = (value: string | number, currency?: string) => {
  return (currency? currencyIcon[currency]: '') + value.toLocaleString();
};

export default GetCurrency;
