import React, { useState } from 'react';

import styles from './FundraiserInfo.module.scss';
import { IDonationInfo, IPublicEvent } from "../../../store/DonationPage/types";
import DefaultEventImage from "../../../assets/images/illustrations/DefaultEventImage.png";
import DonationInfo from "../DonationInfo/DonationInfo";
import { useDispatch } from "react-redux";
import { getDonations } from "../../../store/DonationPage/thunks";
import ShareButtons from "../../../components/ShareButtons/ShareButtons";
import FundraiserStats from "../FundraiserStats/FundraiserStats";
import DonationsProgressCentered
  from "../../../components/progress/DonationsProgressCentered/DonationsProgressCentered";
import { replaceLinks } from "../../../utils/textUrils";
import ProgressEAPage from "../../ProgressScool/ProgressEAPage";

interface Props {
  event: IPublicEvent;
  donations: Array<IDonationInfo>
}


const DONATIONS_PAGE_SIZE = 3;

const FundraiserInfo = ({event, donations}: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const videoUrl = prepareVideoUrl(event.details.video);

  const showMore = () => {
    dispatch(getDonations({eventId: event.id, limit: donations.length + DONATIONS_PAGE_SIZE}));
  }

  setTimeout(() => {
    setIsVisible(true)
  }, 200);

  return (
    <div className={`${styles.FundraiserInfo}  ${isVisible ? styles.visible : styles.hidden}`}>


      <h3 className={styles.title}>Fundraiser Story</h3>

      {videoUrl ?
        <div className={styles.media}>
          <img className={`${styles.photo} ${styles.right}`} src={event.details.photo2 || DefaultEventImage} alt=""/>
          <iframe src={videoUrl} frameBorder="0"
                  allowFullScreen></iframe>
        </div>
        :
        <img className={`${styles.photoRight}`} src={event.details.photo2 || DefaultEventImage} alt=""/>
      }


      <div className={styles.summary}  dangerouslySetInnerHTML={{__html: replaceLinks(event.details.summary)}}/>
      {event.details.objectives && event.details.objectives.length > 0 &&
      <>
        <h3>Fundraiser Objectives</h3>
        <ul>
          {event.details.objectives.map((item) => (
            <li style={{listStyle: 'inside'}} key={item} className={styles.body}>
              {item}
            </li>
          ))}
        </ul>
      </>}

      {event.invite.from.role === 'EA' &&
      <ProgressEAPage/>}

      {donations.length > 0 && <>
        <h3>Donor Wall</h3>
        {donations.map(donation => <DonationInfo key={donation.createdAt}
                                                 donation={donation}
                                                 currency={event?.currency}/>)}

        {donations.length % DONATIONS_PAGE_SIZE !== 0 ? '' :
          <div className={styles.showMore} onClick={showMore}>Show more</div>
        }
      </>}

      <div className={styles.divider}/>

      {event.invite.from.role === 'TM' && <>
        {/*<h3>Fundraiser Goal</h3>*/}
        <DonationsProgressCentered amount={event.progress.raisedSum}
                                   goal={event.goals.fundraising}
                                   donors={event.progress.donationsCount}
                                   currency={event.currency}/>

      </>}

      <FundraiserStats event={event}/>


      <div className={styles.footer}>

        <div className={styles.divider}/>

        <h3>Help spread the word!</h3>
        <p>If you know someone who might be able to help my cause, please send them my donation page!</p>
        <ShareButtons shareLink={event.shareLink}/>


        <div className={styles.dividerSmall}/>

        <div className={styles.small}>
          <span>© {new Date().getFullYear()} Read for My School</span><br/>
          <a href="https://www.readformyschool.com/" target="_blank">www.readformyschool.com</a>
        </div>
      </div>

    </div>
  );
};

function prepareVideoUrl(video: string) {
  if (video && video.indexOf('youtu') !== -1) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = video.match(regExp);
    return match && '//www.youtube.com/embed/' + match[2];
  } else if (video && video.indexOf('vimeo') !== -1) {
    return video
      .replace(/(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g,
        '//player.vimeo.com/video/$1?title=0&byline=0&portrait=0');
  } else {
    return null;
  }
}

export default FundraiserInfo;
