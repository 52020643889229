import React, { useState } from 'react';
import API from '../../REST_API/listApi';
import { IEvents, IState, Leaderboard } from '../../types';
import { GetCurrency, LeaderboardCard, MenuSorting } from '../';
import { arrow, clock, donations, subtract } from './icon';

import styles from './ListLeaderboard.module.scss';
import MemberModal from "../Modal/MemberModal/MemberModal";
import { useSelector } from "react-redux";
import { IPublicEvent } from "../../store/DonationPage/types";
import { currencyIcon } from "../UI/GetCurrency/GetCurrency";

const currentField: {[key: string]: string} = {
  Minutes: 'minsSum',
  Donations: 'raisedSum',
  Books: 'booksSum',
};

function sort(arr: Array<Leaderboard>, field: string) {
  function stringSort(a: number, b: number) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  return arr
    .slice()
    .sort((a, b) =>
      stringSort(Number(a.progress[currentField[field]]), Number(b.progress[currentField[field]]))
    )
    .reverse();
}

interface IListLeaderboard {
  list: Array<Leaderboard>;
  isPublic?: boolean;
  limit?: number;
  style?: any;
  name: string;
}


const ListLeaderboard: React.FC<IListLeaderboard> = ({limit, style, list, isPublic, name}) => {
  const event = useSelector((state: IState) => state.events.data || state.dp.event);
  const orderOptions = prepareSortingOptions(event);
  const [sortBy, setSortBy] = useState(orderOptions.length > 0 ? orderOptions[0].name : '');

  const [memberModalIsOpen, setMemberModalIsOpen] = useState(false);
  const [memberDetails, setMemberDetails] = useState({name: '', teamName: '', shareLink: ''});

  // if (orderOptions.length == 0) return null;

  const openMemberModal = (member: any) => {
    if (event && event.options.accessToDonationPage) {
      setMemberModalIsOpen(true);
      setMemberDetails({
        name: member.user.displayName,
        teamName: member.team ? (member.team.name) : '',
        shareLink: ''
      });
      API.teams.getMemberById(member.id).then(member => {
        setMemberDetails({
          name: member.data.user.displayName,
          teamName: member.data.team.name,
          shareLink: member.data.shareLink
        });
      });
    }
  }
  // @ts-ignore
  // @ts-ignore
  return (
    <div className={styles.wrapperListLeaderboard} style={style || {}}>
      <div className={styles.listLeaderboardHeader}>
        <span className={styles.listLeaderboardHeading}>{name}</span>
        {sortBy && <div className={styles.menuSorting}>
          <MenuSorting
          title={sortBy}
          setSortBy={setSortBy}
          list={orderOptions}
          />
          <img className={styles.arrowOpen} alt='arrow' src={arrow}/>
        </div>}
      </div>
      {orderOptions.length ===0 &&
      <div className={styles.empty}>
        Your team and school leaderboards have been turned off by your event administrator.<br/>
        Please contact your event administrator for details.
      </div>
      }
      {sortBy && sort(list, sortBy).slice(0, limit || 300).map((item: Leaderboard, index: number) => {
        return (
          <LeaderboardCard
            index={index}
            key={item.id}
            isPublic={isPublic}
            item={item}
            isZero={!item.progress[currentField[sortBy]]}
            value={
              sortBy === 'Donations' ?
                <>{currencyIcon[event?.currency||''] + item.progress.raisedSum.toLocaleString()}</> :
                item.progress[currentField[sortBy]].toLocaleString()
            }
            onClick={() => {
              openMemberModal(item)
            }}
          />
        );
      })}

      <MemberModal
        memberName={memberDetails.name}
        teamName={memberDetails.teamName}
        shareLink={memberDetails.shareLink}
        cancel={() => setMemberModalIsOpen(false)}
        isOpen={memberModalIsOpen}
      />
    </div>
  );
};

function prepareSortingOptions(event: IEvents | IPublicEvent | null) {
  const orderOptions = [
    {name: 'Donations', icon: donations},
    {name: 'Minutes', icon: clock},
    {name: 'Books', icon: subtract},
  ];
  if (event && !event.options.showDonations) orderOptions[0].name = '';
  if (event && !event.options.showMinutes) orderOptions[1].name = '';
  if (event && !event.options.showBooks) orderOptions[2].name = '';

  return orderOptions.filter(i => i.name !== '');
}

export default ListLeaderboard;

