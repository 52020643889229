import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { IState, Leaderboard } from '../../../types';

import styles from './LeaderboardCard.module.scss';

interface ILeaderboardCard {
  item: Leaderboard | any;
  index: number;
  isPublic?: boolean;
  value: JSX.Element | string;
  isZero: boolean;
  onClick: () => void;
}

const LeaderboardCard: React.FC<ILeaderboardCard> = ({item, index, isPublic, value, onClick, isZero}) => {
  const currentUser = useSelector((state: IState) => state.progressMy.data);

  // if (!currentUser) return null;
  const isCurrent = currentUser?.id === item.id;
  return isCurrent ? (
    <div className={styles.wrapperLeaderboardCardCurrent} onClick={onClick}>
      <span className={styles.leaderboardCardIdCurrent}>{index + 1}</span>
      <img className={styles.leaderboardCardImg} alt='avatar' src={item.user.avatar}/>
      <div className={styles.leaderboardCardWrapper}>
        <span className={styles.leaderboardCardNamedCurrent}>Me</span>
        {item.team && (
          <span className={styles.leaderboardCardGradedCurrent}>
            {item.team.name}
          </span>
        )}
      </div>
      <span className={cn(styles.leaderboardCardSumdCurrent, isZero && styles.zeroValue)}>
        {value}
      </span>
    </div>
  ) : (
    <div className={styles.wrapperLeaderboardCard} onClick={onClick}>
      <span className={styles.leaderboardCardId}>{index + 1}</span>
      <img className={styles.leaderboardCardImg} alt='avatar' src={item.user.avatar}/>
      <div className={styles.leaderboardCardWrapper} onClick={onClick}>
        <span className={styles.leaderboardCardName}>
          {isPublic ?
            <>{item.user.displayName}</> :
            <>{item.user.firstName} {item.user.lastName}</>}
        </span>
        {item.team && (
          <span className={styles.leaderboardCardGrade}>{item.team.name}</span>
        )}
      </div>
      <span className={cn(styles.leaderboardCardSum, isZero && styles.zeroValue)}>{value}</span>
    </div>
  );
};

export default LeaderboardCard;
