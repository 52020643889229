import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Input, ListLeaderboard, ProgressLoader, Select } from '../../components';
import { getEventMembers } from '../../store/SchoolLeaderboard/schoolLeaderboard.thunk';
import { IState } from '../../types';
import { formatAmount } from "../../components/UI/GetCurrency/GetCurrency";
import { getListTeam } from "../../store/listTeam/listTeam.thunk";

const ProgressEAPage: React.FC = () => {
  const dispatch = useDispatch();
  const event = useSelector((state: IState) => state.dp.event);
  const teams = useSelector((state: IState) => state.listTeam.data);
  const members = useSelector((state: IState) => state.schoolLeaderboard.data);
  const [teamId, setTeamId] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (event) {
      dispatch(getEventMembers(event?.id));
      dispatch(getListTeam(event.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!event || !members) return null;

  const {
    progress: {minsSum, raisedSum},
    goals,
  } = event;


  const membersFiltered = members.filter(
    (member, index) =>
      ((search === ''
        || member.user?.firstName?.toLowerCase().includes(search.toLowerCase())
        || member.user?.lastName?.toLowerCase().includes(search.toLowerCase())
        || member.user?.displayName?.toLowerCase().includes(search.toLowerCase())) && (teamId == 0 || member.team.id == teamId))
  );

  if (!members || members.length < 5) return null;
  return (
    <>
      <ProgressLoader
        valueTime={minsSum}
        valueCoin={raisedSum}
        totalValueTime={goals.readingMins}
        totalValueCoin={goals.fundraising}
        currency={event.currency}
      />

      <h3>Fundraiser Readerboard</h3><br/>
      <Input value={search}
             isSearch={true}
             placeholder="Search by name" onChange={(e: any) => setSearch(e.target.value)}/>
      <br/>
      <Select
        placeholder={'All Teams'}
        value={teamId} onChange={(id) => {setTeamId(id)}}>
        <option value={0}>
          All Teams
        </option>
        {teams?.filter(t => t.size > 0).map(team => (
          <option value={team.id} key={team.id}>
            {team.name}
          </option>))}

      </Select>
      <ListLeaderboard
        name='Top Performers'
        limit={10}
        isPublic={true}
        list={membersFiltered}/>
    </>
  );
};


export default ProgressEAPage;
