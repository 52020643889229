import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ListLeaderboard, ProgressLoader } from '../../components';
import { getEventMembers } from '../../store/SchoolLeaderboard/schoolLeaderboard.thunk';
import { IState } from '../../types';

const ProgressScool: React.FC = () => {
  const dispatch = useDispatch();
  const events = useSelector((state: IState) => state.events.data);
  const schoolLeaderboard = useSelector((state: IState) => state.schoolLeaderboard.data);

  useEffect(() => {
    dispatch(getEventMembers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!events || !schoolLeaderboard) return null;

  const {
    progress: { minsSum, raisedSum },
    goals,
  } = events;
  return (
    <div>
      <ProgressLoader
        valueTime={minsSum}
        valueCoin={raisedSum}
        totalValueTime={goals.readingMins}
        totalValueCoin={goals.fundraising}
      />
      <ListLeaderboard
        style={{ paddingBottom: '110px' }}
        name='School Leaderboard' list={schoolLeaderboard} />
    </div>
  );
};

export default ProgressScool;
